<template>
  <!-- <Transition name="fade" appear> -->
    <section>
      <div class="column left">
        <div class="content">
          <div class="nav-icons">
            <div @click="nextPage(store.currentPage)">
              <img
                class="icon"
                :src="require('@/assets/icons/play.svg')"
              />
              <span>{{store.data[side].det.continue[store.lang]}}</span>
            </div>
            <img
              @click="router.push('../'); store.changePage(1)"
              class="icon"
              :src="require('@/assets/icons/'+closeicon+'.svg')"
            />
          </div>
          <div>
          <Transition name="fast-fade" appear mode="out-in">
            <IconButton
              :icon="require('@/assets/icons/'+store.data[side].button.img)"
              :text="store.data[side].button[store.lang]"
              :width="store.data[side].button.size"
              :key="id"
            />
          </Transition>
          <Transition name="fast-fade" appear mode="out-in">
            <b :key="id">{{store.data[side].det.items[route.params.id-1].title[store.lang]}}</b>
          </Transition>
          <Transition name="fast-fade" appear mode="out-in">
            <p :key="id">{{ store.data[side].det.items[route.params.id-1].text[store.lang] }}</p>
          </Transition>
          </div>
        </div>
        <NavBar :menu="store.data[side].det.items.length" />
      </div>
      <div class="column right">
        <!-- <img :src="require('@/assets/dettagli-'+route.params.id+'.jpg')" alt="" /> -->
        <Transition name="fast-fade" appear mode="out-in">
          <img :key="id" :src="require('@/assets/images/'+side+'/'+store.data[side].det.items[route.params.id-1].img)" />
        </Transition>
      </div>
    </section>
  <!-- </Transition> -->
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/stores/store";
import NavBar from '@/components/NavBar.vue'
import IconButton from '@/components/IconButton.vue'
import { onMounted } from '@vue/runtime-core';

export default {
  props: ["id", 'side', 'closeicon'],
  components: {NavBar, IconButton},
  setup(props) {
    const route = useRoute();
    const router = useRouter()
    const store = useStore();
    console.log(route.params.id);

    const nextPage = (p) => {
      let page = (p+1)
      if (page == store.data[props.side].det.items.length + 1) page = 1;  
      router.push(''+page)
      store.changePage(page)
    }

    onMounted(() => {
      store.currentPage = parseInt(props.id) 
    })

    return { route, router, store, nextPage };
  },
};
</script>

<style scoped>
section {
  flex-direction: row;
  margin-right: 0;
}
section div:last-child {
  justify-content: flex-end;
}

.right {
  width: auto;
}
.nav-icons div {
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.nav-icons div span {
  font-family: JostRegular;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.02em;
  padding-right: 15px;
}

p, b {
  margin-top: 30px;
  margin-left: 10px;
}

</style>