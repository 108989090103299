<template>
  <router-view/>
</template>
<script>
import { useStore } from '@/stores/store';

export default {
  name: 'App',
  setup() {
    const store = useStore();

    return {
      store
    };
  },
  data() {
    return {
      idle : false,
      interval : null
    }
  },
  mounted() {
    this.startIdleTimer();

    // reset idle timer on mouse move
    document.addEventListener('mousemove', () => {
      this.idle = false;
      if(this.interval) clearInterval(this.interval);

      this.startIdleTimer();
    });

    // disable right click
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  },
  methods: {
    startIdleTimer() {
      this.interval = setTimeout(() => {
        this.idle = true;

        clearInterval(this.interval);
      }, 1000 * 60 * 1);
    }
  },
  watch: {
    idle(value) {
      if (value) {
        this.$nextTick(() => {
          // reset pinia store to default state
          this.store.reset();

          this.$router.push('/home');
        });
      }
    }
  }
}
</script>


<style>

</style>
