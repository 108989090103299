<template>
  <Transition name="fade" appear>
    <General side="front" />
  </Transition>
</template>

<script>
import General from "@/components/General.vue";

export default {
  components: { General },
};
</script>

<style>
</style>