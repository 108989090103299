import { defineStore } from "pinia";
import data from "../../data/data.json";

export const defaultState = {
  lang: "it",
  data: data,
  currentPage: 1,
  zoom: 14,
  brightness: 0
};

export const useStore = defineStore("store", {
  state: () => (defaultState),
  getters: {
  },
  actions: {
    changeLang(l) {
      if (this.lang !== l) {
        this.lang = l;
        console.log(this.lang);
      }
    },
    changePage(p) {
      this.currentPage = p;
    },
    zoomImage(z) {
      this.zoom = z;
    },
    changeBrightness(b) {
      // this.brightness = b * 0.1
      console.log(b)
    },
    reset() {
      Object.assign(this.$state, defaultState);
    }
  },
});
