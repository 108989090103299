<template>
  <Detail side="identikit" closeicon="back" />
</template>

<script>
import Detail from '@/components/Detail.vue'
export default {
    components: { Detail }
}
</script>

<style>

</style>