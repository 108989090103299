import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { MotionPlugin } from '@vueuse/motion'
// import VueViewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
import '@/assets/viewer.min.css'

import '@/assets/style.css'
import './registerServiceWorker'

createApp(App).use(router).use(createPinia()).use(MotionPlugin).mount('#app')
