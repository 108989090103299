<template>
  <Transition name="fade" appear>
    <div class="home">
      <Transition name="slide-left" appear>
      <img
        class="backimg"
        :src="require('@/assets/images/home-vecchiodormiente.jpg')"
        alt="Veccio Dormiente"
      />
      </Transition>
      <Transition name="zoom" appear>
        <div class="text">
          <h2>Vecchio dormiente</h2>
          <h1>Esploriamo<br />il capolavoro</h1>
          <h3>di Rembrandt</h3>
          <div class="button-group">
            <button @click="start('it')"><i class="start"></i> Comincia</button>
            <button @click="start('en')"><i class="start"></i> Start</button>
          </div>
        </div>
      </Transition>
      <Transition name="fade" appear>
        <div id="creditos">
          <span>Responsabile del progetto didattico:</span>
          <div class="line"><span>Giorgia Corso e Fabrizio Ferla</span><b>Servizi educativi</b></div>
          <div class="line"><span>Rossella Arcadi</span><b>Ufficio Informatica e strategie digitali educativi</b></div>
          <div class="line"><span>Partner Tecnologico:</span><img :src="require('@/assets/images/visivalab.png')" alt="Visivalab"></div>
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { useStore } from "@/stores/store";

export default {
  name: "HomeView",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const start = (l) => {
      store.changeLang(l);
      router.push("/");
    };

    return { start };
  },
};
</script>

<style scoped>
h1,
h3 {
  font-family: NanumMyeongjoRegular;
  font-weight: 100;
  color: #dbd5b5;
}

h1 {
  font-size: 180px;
  line-height: 180px;
  margin: 30px 0 30px -5px;
  letter-spacing: -0.02em;
}

h3 {
  font-size: 40px;
  letter-spacing: -0.03em;
}

.backimg {
  position: fixed;
  height: 100vh;
  z-index: -1;
  right: 0;
}

.home {
  height: 100vh;
  margin-left: 150px;
  justify-content: center;
}

.text {
  text-align: left;
  align-items: flex-start;
}

.button-group {
  gap: 25px;
  margin: 50px 0;
  flex-direction: row;
}

.start {
  background: url(@/assets/icons/start.svg) no-repeat;
  float: left;
  width: 12px;
  height: 15px;
  margin-right: 10px;
}

button {
  font-size: 13px;
  letter-spacing: 0.05em;
  background: #9d1c00;
  color: #dbd5b5;
  text-transform: uppercase;
  padding: 13px 20px;
}

#creditos {
  position: absolute;
  bottom: 50px;
}
#creditos span {
  font-size: 16px;
}
#creditos b {
  font-size: 10px;
  font-weight: lighter;
}
.line {
  flex-direction: row;
  align-items: flex-end;
  column-gap: 10px;
}
.line:first-of-type {
  margin-top: 15px;
  margin-bottom: 5px;
}

.line:last-of-type {
  align-items: center;
  margin-top: 30px;
}
.line:last-of-type img {
  margin-left: -10px;
}
</style>
