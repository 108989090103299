<template>
  <div class="iconbutton">
    <div class="circle">
      <img :style="{width: width + 'px'}" :src="icon" />
    </div>
    <div class="tag">
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script setup>
const { ref }=require("@vue/reactivity");
const { onMounted }=require("@vue/runtime-core");

const props = defineProps({
  icon: String,
  text: String,
  width: Number
});
</script>

<style scoped>
.iconbutton {
  align-items: center;
  cursor: pointer;
  flex-direction: row;
}
.circle {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: #9d1c00;
  border: 3px solid black;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-sizing: border-box;
}
.tag {
  background: #9d1c00;
  height: 46px;
  width: 164px;
  justify-content: center;
  text-transform: uppercase;
  transform: translateX(-15px);
  z-index: 1;
}
img {
  width: 30px;
}
p {
  font-family: JostRegular;
  line-height: 14px;
  font-size: 13px;
  letter-spacing: 0.05em;
  padding-left: 30px;
}
</style>