<template>
<Transition name="fade" appear>
  <section>
    <div class="inner-section">
      <div class="column">
        <div class="button-group">
          <IconButton
            :icon="require('@/assets/icons/inspection.svg')"
            :text="data.introduzione.buttons[0][store.lang]"
            :width="59"
            @click="router.push('/front')"
          />
          <IconButton
            :icon="require('@/assets/icons/map.svg')"
            :text="data.introduzione.buttons[1][store.lang]"
            :width="32"
            @click="router.push('/provenienza/1')"
          />
          <IconButton
            :icon="require('@/assets/icons/identikit.svg')"
            :text="data.introduzione.buttons[2][store.lang]"
            :width="28"
            @click="router.push({name: 'identikitdettagli', params: { id: 1 } })"
          />
          <IconButton
            :icon="require('@/assets/icons/touch.svg')"
            :text="data.introduzione.buttons[3][store.lang]"
            :width="37"
            @click="router.push('/esplora')"
          />
          <IconButton
            :icon="require('@/assets/icons/gira.svg')"
            :text="data.introduzione.buttons[4][store.lang]"
            :width="35"
            @click="router.push('/back')"
          />
        </div>
        <img :src="require('@/assets/images/intro-vecchiodormiente.png')" alt="" />
      </div>
      <div class="column">
        <div class="content">
          <div class="text">
            <p v-for="(t, index) in data.introduzione.text[store.lang]" :key="index">{{ t }}</p>
          </div>
          <div class="info">
            <p>Rembrandt Harmenszoon van Rijn</p>
            <h2>Vecchio Dormiente</h2>
            <p>1629 - {{ data.introduzione.tipo[store.lang] }}</p>
          </div>
        </div>
      </div>
    </div>
    <NavBar :menu="0" />
  </section>
  </Transition>
</template>

<script>
import data from "../../data/data.json";
import IconButton from "../components/IconButton.vue";
import NavBar from "../components/NavBar.vue";
import { useStore } from "@/stores/store";
import { useRouter } from 'vue-router';

export default {
  components: { IconButton, NavBar },
  setup() {
    const router = useRouter()
    const store = useStore();
    return { router, store, data };
  },
};
</script>
<style scoped>
.button-group {
  row-gap: 36px;
  position: absolute;
  right: 655px;
  bottom: 205px;
}
.column .content {
  width: 100%;
  align-self: flex-end;
  margin-bottom: 50px;
}
.column div:first-of-type {
  justify-content: space-between;
}
img {
  width: 655px;
  height: 755px;
  margin: 67px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.text {
  margin-top: 170px;
}
.text p {
  margin-bottom: 30px;
}

.info {
  border-top: 1px solid #dbd5b5;
  margin: 30px 0;
  padding: 22px 0;
}
</style>