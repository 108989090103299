<template>
<Transition name="fade" appear>
  <section>
    <div class="column left">
      <div class="content">
        <div class="nav-icons">
           <div @click="nextPage(store.currentPage)">
              <img
                class="icon"
                :src="require('@/assets/icons/play.svg')"
              />
              <span>{{store.data.back.det.continue[store.lang]}}</span>
            </div>
          <img
            @click="router.push('/'); store.changePage(1)"
            class="icon"
            :src="require('@/assets/icons/back.svg')"
          />
        </div>
        <div>
          <IconButton
            :icon="require('@/assets/icons/map.svg')"
            :text="store.data.introduzione.buttons[1][store.lang]"
            :width="32"
          />
          <p>
            {{
              store.data.provenienza.det.text[store.currentPage - 1][store.lang]
            }}
          </p>
        </div>
      </div>
      <NavBar :menu="store.data.provenienza.det.text.length" />
    </div>
    <div class="column right">
      <div
        id="map"
        :style="{
          'background-image':
            'url(' +
            require('../assets/images/' + store.data.provenienza.det.map) +
            ')',
        }"
      >
        <div
          v-for="index in 4"
          :key="index"
          class="pintag-cont"
          :style="{
            top: store.data.provenienza.det.pintags[index - 1].h + 'px',
            left: store.data.provenienza.det.pintags[index - 1].w + 'px',
          }"
        >
          <img
            v-motion
            :initial="{ opacity: 0, scale: 0 }"
            :enter="{
              opacity: 1,
              scale: 1,
              transition: { delay: index * 370, stiffness: 40 },
            }"
            :class="{ active: (store.data.provenienza.det.active[(route.path.slice(-1)-1)].includes(index-1)) }"
            class="pintag"
            :alt="index"
            :src="
              require('@/assets/icons/' + store.data.provenienza.det.pinicon)
            "
          />
        </div>
      </div>
    </div>
  </section>
</Transition>
</template>

<script>
import IconButton from "@/components/IconButton.vue";
import NavBar from "@/components/NavBar.vue";
import { useStore } from "@/stores/store";
import { useRoute, useRouter } from "vue-router";

export default {
  components: { NavBar, IconButton },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const nextPage = (p) => {
      let page = (p+1)
      if (page == store.data.provenienza.det.text.length + 1) page = 1;  
      router.push(''+page)
      store.changePage(page)
    }
    return { store, router, route, nextPage };
  },
};
</script>

<style scoped>
section {
  flex-direction: row;
  margin-right: 0;
  overflow: hidden;
}

#map {
  position: fixed;
  right: 0;
  width: 1002px;
  height: 100vh;
  background-repeat: no-repeat;
}
.pintag-cont {
  position: absolute;
  width: 35px;
  height: 57px;
  align-items: center;
  justify-content: end;
}
.pintag {
  width: 20px;
  position: relative;
  transition: width .2s ease;
}
.active {
  width: 35px;
  transition: width .5s ease;
  z-index: 1;
  filter: drop-shadow(0px 0px 30px #000000);
}
.column {
  width: 740px;
}

p {
  margin-top: 30px;
  margin-left: 10px;
}

.nav-icons div {
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.nav-icons div span {
  font-family: JostRegular;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.02em;
  padding-right: 15px;
}
</style>