<template>
  <Detail side="back" closeicon="close" />
</template>

<script>
import Detail from "@/components/Detail.vue";
export default {
  components: { Detail },
};
</script>

<style>
</style>