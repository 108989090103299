<template>
  <!-- <Transition name="fade" appear> -->
  <section>
    <div class="column left">
      <div class="content">
        <div class="nav-icons">
          <img
            @click="router.push('/')"
            class="icon"
            :src="require('@/assets/icons/back.svg')"
            alt="Back"
          />
        </div>
        <div>
          <IconButton
              :icon="require('@/assets/icons/'+store.data[side].button.img)"
              :text="store.data[side].button[store.lang]"
              :width="store.data[side].button.size"
            />
          <p>{{ store.data[side].gen.text[store.lang] }}</p>
        </div>
      </div>
      <NavBar />
    </div>
    <div class="column">
      <div id="scopri-img"
        :style="{
          'background-image':
            'url(' + require('../assets/images/' + store.data[side].gen.image) + ')',}">
        <img
          v-motion
          :initial="{ opacity: 0, scale: 0,}"
          :enter="{ opacity: 1, scale: 1, transition: { delay: index * 200, stiffness: 40},}"
          v-for="index in store.data[side].gen.pintags.length"
          :key="index"
          class="pintag"
          :style="{
            top: store.data[side].gen.pintags[index - 1].h + 'px',
            left: store.data[side].gen.pintags[index - 1].w + 'px',
          }"
          @click="router.push({ name: side+'dettagli', params: { id: index++ } })"
          :alt="index"
          :src="require('@/assets/icons/inspection-circle.svg')"
        />
      </div>
    </div>
  </section>
  <!-- </Transition> -->
</template>

<script>
import IconButton from "@/components/IconButton.vue";
import NavBar from "@/components/NavBar.vue";
import { useStore } from "@/stores/store";
import { useRouter } from "vue-router";
export default {
  props: ["side"],
  components: { IconButton, NavBar },
  setup() {
    const store = useStore();
    const router = useRouter();
    return { store, router };
  },
};
</script>

<style scoped>
section {
  flex-direction: row;
  margin-right: 105px;
}

#scopri-img {
  width: 820px;
  background-repeat: no-repeat;
  background-position: center;
}

.content p {
  width: 490px;
}

p {
  margin-top: 30px;
  margin-left: 10px;
}

.pintag {
  position: absolute;
  width: 50px;
  cursor: pointer;
  filter: drop-shadow(2px 0px 15px rgba(0, 0, 0, 0.4));
}
.pintag:active {
  filter: grayscale(0.5);
}

.nav-icons {
  justify-content: flex-end;
}
</style>