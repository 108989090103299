import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import IntroduzioneView from "../views/IntroduzioneView.vue";
import FrontGeneralView from "../views/FrontGeneralView.vue";
import FrontDetailView from "../views/FrontDetailView.vue";
import BackGeneralView from "../views/BackGeneralView.vue";
import BackDetailView from "../views/BackDetailView.vue";
import ProvenienzaView from "../views/ProvenienzaView.vue";
import EsploraView from "../views/EsploraView.vue";
import IdentikitView from "../views/IdentikitView.vue";


const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/",
    name: "intro",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ "../views/IntroduzioneView.vue"),
    component: IntroduzioneView,
  },
  {
    path: "/front",
    name: "front",
    // component: () => import(/* webpackChunkName: "about" */ "../views/FrontGeneralView.vue"),
    component: FrontGeneralView,

  },
  {
    path: "/front/dettagli/:id",
    name: "frontdettagli",
    // component: () => import(/* webpackChunkName: "about" */ "../views/FrontDetailView.vue"),
    component: FrontDetailView,
    props: true,
  },
  {
    path: "/back",
    name: "back",
    // component: () => import(/* webpackChunkName: "about" */ "../views/BackGeneralView.vue"),
    component: BackGeneralView,
  },
  {
    path: "/back/dettagli/:id",
    name: "backdettagli",
    // component: () => import(/* webpackChunkName: "about" */ "../views/BackDetailView.vue"),
    component: BackDetailView,
    props: true,
  },
  {
    path: "/provenienza/:id",
    name: "provenienza",
    // component: () => import(/* webpackChunkName: "about" */ "../views/ProvenienzaView.vue"),
    component: ProvenienzaView,
  },
  {
    path: "/esplora",
    name: "esplora",
    component: EsploraView
  },
  {
    path: "/identikit/:id",
    name: "identikitdettagli",
    // component: () => import(/* webpackChunkName: "about" */ "../views/ProvenienzaView.vue"),
    component: IdentikitView,
    props: true
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
