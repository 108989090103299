<template>
  <nav>
    <img @click="router.push('/home'); store.$reset()" :src="require('@/assets/icons/home.svg')" alt="home" />
    <Transition name="big-zoom" appear>
      <ul class="pag" v-if="menu !== 0">
        <li @click="
          router.push('' + index);
        store.currentPage = index;
        " v-for="index in menu" :class="{ active: index == store.currentPage }" :key="index">
          {{ index }}
        </li>
      </ul>
    </Transition>
    <div class="controls" v-if="imgcontrols">
      <div class="zoom">
        <img @click="$emit('zoomout')" src="@/assets/icons/zoomout.svg" />
        <h4>{{ zoom }}%</h4>
        <img @click="$emit('zoomin')" src="@/assets/icons/zoomin.svg" />
      </div>
      <div class="slider">
        <img src="@/assets/icons/slider1.svg" />
        <input v-model="store.brightness" type="range" min="0" max="1" step="0.02" />
        <img src="@/assets/icons/slider2.svg" />
      </div>
    </div>
    <div class="lang">
      <h4 @click="store.changeLang('it')">IT</h4>
      <i>|</i>
      <h4 @click="store.changeLang('en')">EN</h4>
    </div>
  </nav>
</template>

<script>
import { useStore } from "@/stores/store";
import { useRouter } from "vue-router";
import { defineProps } from "vue";

export default {
  props: { 
    menu: Number,
    zoom: Number,
    imgcontrols: {
      type: Boolean,
      default: false
    } 
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    return { store, router };
  }
};
</script>

<style scoped>
nav {
  display: flex;
  border-top: 1px solid #dbd5b5;
  width: 100%;
  justify-content: space-between;
  padding: 25px 0;
}

img {
  cursor: pointer;
  width: 38px;
  margin-right: 15px;
}

.lang {
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  transform: translateX(14px);
}

ul {
  display: inline-flex;
  align-items: center;
}

li {
  list-style-type: none;
  font-family: JostRegular;
  font-size: 20px;
  padding: 8px 16px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  width: 13px;
  text-align: center;
}

h4 {
  cursor: pointer;
  padding: 10px 15px;
}

h4:active,
img:active {
  filter: grayscale(1) invert(0.9);
}

i {
  font-size: 30px;
  margin-top: -5px;
}

.active {
  background: #9d1c00;
  transition: 500ms;
}

.controls {
  flex-direction: row;
  justify-content: center;
  column-gap: 200px;
}

.controls h4:active {
  filter: none;
}

.controls h4 {
  padding: 0;
}

.slider,
.zoom {
  flex-direction: row;
  align-items: center;
}

.zoom img {
  width: 32px;
  margin: 0 10px;
  padding: 5px 20px;
}

.slider img:first-child {
  width: 20px;
  margin-right: 20px;
}

.slider img:last-child {
  width: 40px;
  margin-left: 20px;
}

.slider input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  width: 320px;
  background: #dbd5b5;
  outline: none;
}

.slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #dbd5b5;
  cursor: pointer;
}

.slider input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #dbd5b5;
  cursor: pointer;
}
</style>