<template>
  <div v-show="show" id="popup">
    <div id="content">
      <div class="nav-icons">
        <img
          @click="show = false"
          class="icon"
          :src="require('@/assets/icons/close.svg')"
          alt="Close"
        />
      </div>
      <IconButton
        :icon="require('@/assets/icons/touch.svg')"
        :text="store.data.introduzione.buttons[3][store.lang]"
        :width="37"
      />
      <p>{{ store.data.esplora.text[store.lang] }}</p>
    </div>
  </div>
</template>

<script>
import IconButton from "@/components/IconButton.vue";
import { useStore } from "@/stores/store";
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  components: { IconButton },
  setup() {
    const store = useStore();
    const router = useRouter();
    const show = ref(true);

    onMounted(() => {
      setTimeout(() => {
        show.value = false;
      }, 7000);
    });

    return { store, router, show };
  },
};
</script>

<style scoped>
#popup {
  position: absolute;
  left: 0;
  height: 100vh;
  background: rgba(3, 4, 2, 0.7);
  width: 946px;
  z-index: 9999;
}
#content {
  margin: 0 130px;
}

#content p {
  margin-top: 30px;
  margin-left: 10px;
}
.nav-icons {
  justify-content: end;
  margin-bottom: 220px;
}
</style>