<template>
  <Transition name="slide-right" appear>
    <Popup />
  </Transition>
  <section>
    <img
      @click="
        router.push('../');
        store.changePage(1);
      "
      class="icon back"
      :src="require('@/assets/icons/back.svg')"
    />
    <div class="inner-section">
      <div id="imgcont">
        <l-map
          style="height: 100%; width: 100%"
          :center="center"
          :zoom="store.zoom"
          ref="map"
          :minZoom="minZoom"
          :zoomAnimation="true"
          :tileSize="256"
          @update:zoom="store.zoomImage($event)"
          :useGlobalLeaflet="false"
          :maxZoom="maxZoom"
          :maxBounds="maxBounds"
          :zoomSnap="0.01"
        >
          <l-tile-layer
            url="http://localhost:9000/darker/{z}/{x}/{y}.png"
            :noWrap="true"
          />
          <l-tile-layer
            url="http://localhost:9000/lighter/{z}/{x}/{y}.png"
            :noWrap="true"
            :opacity="+store.$state.brightness"
          />
        </l-map>
      </div>
    </div>
    <NavBar
      @zoomout="changeZoom(-1)"
      :zoom="getZoomPercentage()"
      @zoomin="changeZoom(1)"
      :imgcontrols="true"
    />
  </section>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Popup from "@/components/Popup.vue";
import { useStore, defaultState } from "@/stores/store";
import { onMounted, ref } from "@vue/runtime-core";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet";
import { useRouter } from "vue-router";

export default {
  components: { NavBar, LMap, LTileLayer, Popup },
  data() {
    return {
      map: null,
      center: [-0.028525996770867548, 0.02231415165752891],
      maxBounds: [
        [0.00006437301636765363 + 0.002, -0.00021457672119140628 - 0.002],
        [-0.057334890333475094 - 0.002, 0.044846534729003906 + 0.002],
      ],
      minZoom: 14,
      maxZoom: 18,
    };
  },
  methods: {
    changeZoom(val) {
      this.store.zoomImage(
        Math.min(Math.max(this.store.zoom + val, this.minZoom), this.maxZoom)
      );
    },
    getZoomPercentage() {
      return Math.round(
        ((this.store.zoom - this.minZoom) / (this.maxZoom - this.minZoom)) * 100
      );
    },
  },
  // on exit
  beforeUnmount() {
    this.store.zoomImage(defaultState.zoom);
    this.store.brightness = defaultState.brightness;
  },
  
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(() => {});

    return {
      store,
      router,
    };
  },
};
</script>

<style>
#imgcont {
  width: 100%;
  text-align: center;
  display: inline-block;
  margin: 20px 0px 20px 0px;
  border: 1px solid black;
  overflow: hidden;
}
.back {
  position: absolute;
  z-index: 9998;
  bottom: 181px;
}

#imgcont img {
  height: 100%;
}

.viewer-canvas {
  display: inline-block;
}

/* hide leaflet controls */
.leaflet-control-container {
  display: none;
}

.leaflet-container {
  background-color: rgba(255, 0, 0, 0);
}
</style>